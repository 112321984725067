.content {
  margin-top: 10px;
  display: flex;
}
.info {
  flex-grow: 1;
}
.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 40px;
}
.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 20px;
}
.statistics {
  margin-top: -30px;
  margin-bottom: 7px;
}
.aside {
  flex-shrink: 0;
}
.diagram {
  width: 300px;
}

.diagram p {
  text-align: center;
  margin-bottom: 7px;
}
.report {
  flex-shrink: 0;
  border: 1px solid rgba(0,0,0, 0.1);
  border-radius: 5px;
  padding: 5px;
}
.reportHead {
  display: flex;
  align-items: center;
}
.reportFooter {
  display: flex;
  justify-content: space-between;
}

.popupText {
  width: 400px;
}

.analyticsBtn {
  font-size: 14px;
  padding: 0px 7px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #4ec0e4;
  border-color: #4ec0e4;
  line-height: 1.5715;
  text-decoration: none;
  margin-left: 20px;
  flex-shrink: 0;
}
.analyticsBtn:hover {
  background-color: #4ec0e4;
  color: #fff;
}
