.custom-select {
  width: 460px;
  &__wrap {
    position: relative;
  }
  &__head {
    width: 100%;
    height: 32px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    &:hover {
      border-color: #4ec0e4;
    }
  }
  &__search {
    position: relative;
    input {
      display: block;
      width: 100%;
      height: 32px;
      padding: 0 25px 0 11px;
      background-color: transparent;
      border-radius: 10px 10px 0 0;
      border: none;
      font-size: 14px;
      font-family: inherit;
      box-sizing: border-box;
      outline: none;
      transition: border 0.1s linear;
      &::placeholder {
        opacity: 0.5;
      }
      &:focus {
        border-color: #4ec0e4;
      }
    }
    span {
      position: absolute;
      top: 6px;
      right: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
    }
  }
  &__current {
    position: relative;
    height: 30px;
    padding: 0 11px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap;
    transition: border 0.1s linear;

    span {
      position: relative;
      //z-index: 1;
      display: block;
      padding-right: 18px;
      color: rgba(0,0,0,.85);
      font-size: 14px;
      line-height: 30px;
      overflow: hidden;
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        //z-index: 2;
        content: '';
        display: block;
        width: 40px;
        background: linear-gradient(to right, rgba(#fff, 0), #fff 85%);
        pointer-events: none;
      }
    }
    svg {
      display: block;
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 11px;
      fill: #bfbfbf;
      transition: 0.2s ease-in;
    }
  }
  &.interval {
    width: 140px;
  }
  &.wide {
    width: 100%;
  }
  &.active {
    .custom-select__wrap {
      z-index: 50;
    }
    .custom-select-drop {
      display: block;
    }
    .custom-select__head {
      transition: border 0.1s linear;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .custom-select__search input {
      border-radius: 10px 10px 0 0;
      transition: border 0.1s linear;
    }
    .custom-select__current {
      //transition: border 0.1s linear;
      //border-radius: 10px 10px 0 0;
      //box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

      svg {
        transform: rotateZ(180deg);
        transition: 0.2s ease-in;
      }
    }
  }
  &.disabled {
    .custom-select__head {
      &:hover {
        border-color: #d9d9d9;
      }
    }
    .custom-select__current {
      cursor: not-allowed;
      background-color: #F5F5FA;
      span:after {
        display: none;
      }
    }
    .custom-select__current:hover {
      border-color: #d9d9d9;
    }
  }
}
.custom-select-drop {
  display: none;
  position: absolute;

  width: 100%;
  padding: 0;
  top: 32px;
  left: 0;
  right: 0;
  border: 1px solid #d9d9d9;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.custom-select-list {
  max-height: 256px;
  width: 100%;
  overflow: auto;
  &__empty {
    width: 100%;
    padding: 5px 11px;
    text-align: center;
  }
  &__item {
    width: 100%;
    padding: 5px 11px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      background: rgba(78, 192, 228, 0.1);
    }
    &.current {
      color: #4ec0e4;
      background: rgba(78, 192, 228, 0.1);
    }
  }
}

@media (max-width: 576px) {
  .custom-select__wrap {
    width: calc(100% - 114px);
    min-width: 150px;
  }
} 
