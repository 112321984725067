.title {
  position: relative;
  display: inline-block;
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  text-transform: capitalize;
}

.title::before {
  position: absolute;
  content: "";
  bottom: -6px;
  left: 0;
  width: 130px;
  height: 1px;
  background: var(--primary-color);
}
