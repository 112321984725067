.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.description {
  margin-top: 20px;
  margin-bottom: 16px;
}
.pressSecretaryInfoItem {
  margin-bottom: 50px;
}
