.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  background: transparent;
}

.textItem {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a6a6a6;
}

.textItem .title {
  margin-bottom: 4px;
  min-height: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.4;
}

.imageBox {
  width: 16px;
  height: 16px;
  display: flex;
  margin-bottom: 4px;
}

.itemImage {
  width: 100%;
  height: auto;
  margin: auto;
}

.counter {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--secondary-text-color);
  text-align: center;
}
