.table {
  width: 100%;
}

.list {
  position: relative;
  display: grid;
  grid-template-columns: 49% repeat(4, 13%);
  grid-gap: 0;
  margin: 0 0 24px 0;
  padding: 0 19px 0 19px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  list-style-type: none;
}

.list:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -24px;
  width: 100%;
  height: 6px;
  background: #76dbfb;
  border-radius: 0px 0px 8px 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.item {
  margin: 0;
  padding: 0;
  align-self: center;
}

.item:nth-child(1),
.data_header:nth-child(1) {
  justify-self: flex-start;
}

.item:nth-child(n + 2),
.data__nums:nth-child(n + 2) {
  justify-self: flex-end;
}

.data__nums:nth-child(2) {
  justify-self: right;
  width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #000000;
  opacity: 0.35;
}

.data {
  display: grid;
  grid-template-columns: 33% 20% repeat(4, 11%);
  grid-gap: 0;
  padding: 10px 15px 10px 10px;
  background: rgba(255, 255, 255, 1);
}

.data:nth-child(even) {
  background: rgba(238, 242, 244);
  border-radius: 0px 0px 8px 8px;
}

.data:nth-last-child(1) {
  border-radius: 0 0 10px 10px;
}

.data__header,
.data__nums {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  max-width: 80%;
  color: #000000;
  text-decoration: none;
}

.data__nums {
  font-size: 13px;
}
