.search {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.fieldList {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.field {
  flex-shrink: 0;
  width: 300px;
  margin-right: 15px;
}

.count {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary-color);
  margin-left: auto;
  align-self: center;
  flex-shrink: 0;
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.list a {
  text-decoration: none;
}

@media (max-width: 576px) {
  .search {
    display: block;
  }
  .count {
    padding-left: 0;
    margin: 0;
  }
}
