.selectBox {
  position: relative;
  border-radius: 10px;
  display: flex;
}

.solid {
  background-color: #f6f7fa;
}

.outline {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.selectBox select {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1;
  padding: 11px 32px 11px 20px;

  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  max-width: 100%;
}
.selectBox select:not(:disabled) {
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
