.grid {
  display: grid;
  align-items: start;
  gap: 24px 0px;
  grid-template-areas:
    "search search search filters"
    "posts posts posts posts";
  grid-template-columns: 1fr 1fr 1fr minmax(max-content, 0px);
}
.expand {
  display: grid;
  align-items: start;
  gap: 24px;
  grid-template-areas:
    "search search search filters"
    "posts posts posts posts";
  grid-template-columns: 1fr 1fr 1fr minmax(max-content, 280px);
  grid-template-areas:
    "search search search filters"
    "posts posts posts filters";
}
.searchPanel {
  grid-area: search;
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  padding-bottom: 12px;
}
.search {
  width: 300px;
  flex-shrink: 0;
}
.fieldWrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.fieldContainer {
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% / 3) - 10px);
  margin-left: 10px;
}
.label {
  margin-right: 15px;
  margin-left: 15px;
}

.filterArea {
  grid-area: filters;
  max-width: 280px;
  height: 0;
  margin: 0;
  position: absolute;
  right: 0px;
  top: 235px;
}
.publications {
  grid-area: posts;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 35px;
  top: 235px;
}
