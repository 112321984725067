.search {
  display: flex;
  align-items: flex-end;
  /*max-width: 323px;*/
}

.field {
  max-width: unset;
  width: 320px;
  margin-top: 0;
  margin-left: 10px;
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
