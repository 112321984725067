.topic {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.keyWords{
  display: flex;
  align-items: baseline;
}
label{
  margin-right: 10px;
}

input{
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all .3s;
}