.content {
  margin-top: -4px;
}

.filters {
  font-size: 12px;
  line-height: 120%;
  color: #212121;
}

.header {
  padding: 3px 10px;
  position: absolute;
  right: 0px;
  top: -50px;
  background: #fff;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 10px 20px;
}

.expandLabel {
  font-weight: 500;
  font-size: 14px;
  color: #212121;
}

.subTitle {
  display: block;
  margin-top: 10px;
  margin-bottom: 4px;
  opacity: 0.6;
}

.sourceLine {
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}

.sourceLine .subTitle {
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.reset {
  margin-top: 16px;
}

.check {
  display: flex;
  align-items: center;
  width: 32%;
}

.checkGroup,
.check + .check {
  margin-top: 16px;
}

.checkGroup {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.checkBox {
  margin-right: 3px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.expandInput {
  position: absolute;
  height: 15px;
  width: 15px;
  right: -15px;
  width: 100%;


    border: 1px solid #7a7a7a;
    border-radius: 4px;
    background: #fff;
    display: none;
      position: absolute;
      width: 100%;
      min-width: 21px;
      height: 50px;

}

.checkText {
  padding-top: 1px;
}
