@font-face {
  font-family: "Gilroy";
  src: url("./gilroy/gilroy-Bold.eot");
  src: url("./gilroy/gilroy-Bold.woff2") format("woff2"),
    url("./gilroy/gilroy-Bold.woff") format("woff"),
    url("./gilroy/gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./gilroy/gilroy-Medium.eot");
  src: url("./gilroy/gilroy-Medium.woff2") format("woff2"),
    url("./gilroy/gilroy-Medium.woff") format("woff"),
    url("./gilroy/gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./gilroy/gilroy-Regular.eot");
  src: url("./gilroy/gilroy-Regular.woff2") format("woff2"),
    url("./gilroy/gilroy-Regular.woff") format("woff"),
    url("./gilroy/gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
