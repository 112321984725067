.container {
  position: relative;
  height: 36px;
  margin-right: 10px;
}

.input {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
}

.hasIcon {
  padding-right: 40px;
}

.icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6px;
  top: 7px;
  border: 0;
  background-color: #fff;
  z-index: 1;
}

.iconClickable {
  cursor: pointer;
}

.icon-search {
  background-image: url(./icon/search.png);
}

@media (max-width: 576px) {

  .input {
    width: 90%;
  }
  .icon {
    right: 33px;
  }
} 
