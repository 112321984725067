.topic {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.topic {
  border-left: 10px solid grey;
}

.trending_negative {
  border-left: 10px solid red;
}

.trending_positive {
  border-left: 10px solid greenyellow;
}