.box {
  position: relative;
  height: 15px;
  width: 15px;
}

.checkbox {
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  background: #fff;
  flex-shrink: 0;
}

.box input {
  margin: 0;
  padding: 0;
}

.box input,
.checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
}

.box input {
  opacity: 0;
  z-index: 10;
}

.box input:checked + .checkbox {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  background-image: url("./icon/check.svg");
  background-repeat: no-repeat;
  background-size: 8px 8px;
  background-position: 3px 3px;
}

.box:hover .checkbox {
  background-color: #fcfcfc;
  transition: 0.3;
  border-radius: 4px;
}
