.root {
  /*background-color: #fff;*/
  background-color: #1B2534;
}

.nav,
.title,
.link,
.item,
.list,
.childlink {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.childlink {
  margin-left: 50px;
}

.child{
  background: linear-gradient(to right, rgba(2, 117, 177, 0.1), rgba(2, 117, 177, 0.6));
}

.parent{
  position: absolute;
  top: 0;
  width: 10px;
  color: white;
  transform: translateY(-50%);
  transition: transform .3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
}

.parent_left {
    background-color: transparent;
    top: 10px;
    left: 0;
    width: 20px;
    height: 1px;
    display: block;
    transform: rotate(-58deg);
    float: right;
    border-radius: 2px;
}
.parent_left:after {
    content: "";
    background-color: #337AB7;
    width: 11px;
    height: 2px;
    display: block;
    float: right;
    border-radius: 6px 10px 10px 6px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
}

.parent_right {
  background-color: transparent;
  top: 0px;
  left: 26px;
  width: 0px;
  height: 1px;
  display: block;
  transform: rotate(58deg);
  float: right;
  border-radius: 2px;
}
.parent_right:after {
  content: "";
  background-color: #337AB7;
  width: 10px;
  height: 2px;
  display: block;
  float: right;
  border-radius: 10px 6px 6px 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.open{
  margin-top: -5px;
}
.open .parent_left {
  transform-origin: center center;
  transform: rotate(50deg);
  width: 19px;
}
.open .parent_right {
  transform-origin: center center;
  transform: rotate(-50deg);
}

.nav {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.8;
}

.logo {
  padding: 14px 19px 11px 23px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.img {
  object-fit: contain;
}

.link {
  margin-left: 22px;
}

.title {
  margin-left: 22px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.list {
  padding-top: 10px;
  border-top: 1px solid rgba(255,255,255, 0.1);
  display: flex;
  flex-direction: column;
  list-style: none;
}

.item {
  position: relative;
  padding: 12px 19px 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  border-left: 4px solid transparent;
  background: transparent;

  text-decoration: none;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
}

.itemHidden {
  cursor: default;
  opacity: 0.3;
}

.active,
.item:hover {
  border: none;
  border-left: 4px solid #0275B1;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);*/
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to right, rgba(2, 117, 177, 0.1), rgba(2, 117, 177, 0.6));
}
.itemHidden:hover {
  cursor: default;
  color: inherit;
}

.item:nth-last-child(2) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.topic {
  border-left: 10px solid grey;
}

.trending_negative {
  border-left: 10px solid red;
}

.trending_positive {
  border-left: 10px solid greenyellow;
}

@media (max-width: 576px) {
  .item,
  .container {
    padding: 14px 15px;
  }
  .childlink {
    margin-left: -5px;
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .link,
  .title{
    display: none;
  }
}
