.layout {
  display: flex;
  min-height: 100%;
}
.navigation {
  flex-shrink: 0;
  position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 230px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
}

.navigation::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
}
.content {
  margin-left: 255px;
  width: calc(100% - 255px);
}

@media (max-width: 576px) {
  .content {
    padding: 12px 16px;
    margin-left: 85px;
    width: unset;
  }
  .navigation {
    flex-shrink: 0;
    position: fixed;
      width: 82px;
  }
}
