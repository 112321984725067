.title {

}
.tables {
    margin-top: 24px;
    display: flex;
}
.table,
.panel {
    flex-shrink: 0;
    width: calc(50% - 12px);
}
.panel {
    margin-left: 24px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 22px 18px 0;
}
.selectors {
    display: flex;
}
.selectors > div {
    margin-left: 12px;
}
.subHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 18px;
    margin-bottom: 10px;
}
.subtitle {
    line-height: 33px;
}
.subtitleDate {
    width: 240px;
}
.chartHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chartTitle {
    display: block;
    text-align: center;
    margin-bottom: 4px;
}
.chartFooter {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.btnPublic {
    font-size: 14px;
    padding: 0 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #4EC0E4;
    color: #4EC0E4;
    line-height: 1.5715;
}
.btnComment {
    margin-left: 20px;
    font-size: 14px;
    padding: 0 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #8ACE21;
    color: #8ACE21;
    line-height: 1.5715;
}
.btnPublicActive {
    font-size: 14px;
    padding: 0 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #4EC0E4;
    border: 1px solid #4EC0E4;
    color: #ffffff;
    line-height: 1.5715;
}
.btnCommentActive {
    margin-left: 20px;
    font-size: 14px;
    padding: 0 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #8ACE21;
    border: 1px solid #8ACE21;
    color: #ffffff;
    line-height: 1.5715;
}
.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}
.topTable {
    width: 100%;
}
.topTableHead {
    font-weight: 500;
}
.topTableHead .topTableCol {
    font-size: 14px;
}

.topTableRow {
    padding: 3px 10px;
}

.topTableCol {
    width: 80px;
    font-size: 13px;
    color: #000000;
}
.topTableColWide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #000000;
}
.topTableBody {

}
.topTableBody .topTableRow:nth-child(odd) {
    background-color: rgba(238, 242, 244, 1);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}
.topTable td {
    padding: 10px 5px;
}
.topTable td:first-child {
    padding-left: 18px;
}
.topTable td:last-child {
    padding-right: 18px;
}
.topTable .topTableHead td {
    padding-top: 6px;
    padding-bottom: 6px;
}
.topTableName {
    color: inherit;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}
.topTableLink {
    color: #0d6efd;
    opacity: 0.7;
    font-size: 12px;
    display: block;
    text-decoration: none;
    margin-top: 3px;
}
