.search {
  display: flex;
  align-items: flex-end;
}
.fieldRow {
  display: flex;
  align-items: flex-end;
}
.fieldRow:not(:first-child) {
  margin-top: 4px;
}
.field {
  flex-shrink: 0;
  width: 300px;
  margin-right: 10px;
}
.field > span {
  font-size: 14px;
  line-height: 14px;
}
.count {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary-color);
  margin-left: auto;
  padding-left: 30px;
  align-self: center;
  flex-shrink: 0;
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
input::placeholder {
  color: black;
  width: 155px;
}

@media (max-width: 576px) {
  .search {
    display: block;
  }
  .count {
    padding-left: 0;
  }
  .fieldRow {
    display: block;
  }
}
