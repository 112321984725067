.btn {
  font-size: 14px;
  padding: 0px 7px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #4ec0e4;
  background: #fff;
  border-color: #4ec0e4;
  line-height: 1.5715;
  border-radius: 10px;
  margin-top: 10px;
}
.btn:hover {
  background-color: #fafafa;
}
.btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.active {
  font-weight: bold;
  color: #ffffff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.active:hover {
  background-color: var(--primary-darker-color);
  color: #fff;
}
