.postactions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 12px;
  padding: 12px;
  height: 100%;
}

.icon {
  position: relative;
  width: 32px;
  height: 32px;
  background: #f6f7fa;
  border-radius: 50%;
  border: 0;
  display: flex;
  cursor: pointer;
}

.icon img {
  margin: auto;
}
