.loaderContainer,
.errorContainer {
  margin: 30px auto;
}
.loaderContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 60px auto;
}
.errorContainer {
  color: rgb(228, 65, 33);
  background: rgba(227, 64, 32, 0.15);
  border-radius: 10px;
  padding: 18px;
}
