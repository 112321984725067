.container,
.title,
.count {
  margin: 0;
  padding: 0;
}

.container {
  padding: 12px 24px;
  min-height: 99px;
  gap: 16px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  color: red;
}

.title {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}

.count {
  font-size: 36px;
  line-height: 43px;
  color: var(--primary-color);
}
